import { Image } from '@honeycomb/ui-core';
import { ErrorLayout, UnexpectedError, ErrorProps } from '@honeycomb/ui';
import { Logo } from '../components/Logo';
import { ErrorMasthead } from '../components/error/ErrorMasthead';

type Props = {
    error: ErrorProps;
};

export default function Error({ error }: Props) {
    return (
        <UnexpectedError error={error}>
            <Image src={{ xs: '/images/ice-cream-small.png', m: '/images/ice-cream.png' }} />
        </UnexpectedError>
    );
}

Error.getLayout = (page: React.ReactElement, props: Record<string, unknown>) => {
    return (
        <ErrorLayout logo={<Logo />} masthead={<ErrorMasthead />} {...props}>
            {page}
        </ErrorLayout>
    );
};

export const getStaticProps = async () => {
    return {
        props: {
            error: {
                statusCode: 404,
            },
        },
    };
};
